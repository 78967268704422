<script>
	import Event from "../../components/skybox/event.vue";
	import moment from 'moment';
	moment.locale('hu');

	export default {
		components: { Event },
		mounted() {
			this.$store.commit('showNavigation');
			this.$store.commit('removeHeaderLink')
			this.$store.commit('setSkyboxTitle', 'event-list-page-title')
			this.$store.commit('setSkyboxText', '')

			setTimeout(() => {
				this.$store.commit('setApploading', false);
			}, 5000);
		},
		computed: {
			dates() {
				let months = [];
				let years = [];
				years.push(new Date().getFullYear());
				years.push(new Date().getFullYear() + 1);

				years.forEach(year => {
					moment.months().map((monthName) => {
						if(this.$store.getters.hasEventInMonth(year, monthName) == true) {
							months.push({
								"year": year,
								"month": monthName
							});
						}
					})
				})

				return months;
			}
		},
		methods: {
			getEventsByMonth(year, monthName) {
				return this.$store.getters.getEventsByMonth(year, monthName);
			}
		},
		created() {
			this.$store.dispatch('loadEvents');
		}
	}
</script>

<template>
	<div class="wrapper">

		<div class="headline">
			<h1>{{ $t('event-list-title')}}</h1>
		</div>

		<div class="month-group" v-for="row in dates" :key="row.year + row.month">
			<h2 class="month">{{ row.month }}</h2>

			<div class="events">
				<event v-for="(event, eKey) in getEventsByMonth(row.year, row.month)" :key="'event'+eKey" :event="event"></event>
			</div>
		</div>

		<br>
	</div>
</template>
