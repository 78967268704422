<script>
import moment from 'moment';
import smartImage from '../smart-image.vue';
moment.locale('hu');

export default {
  components: { smartImage },
    props: {
        event: Object
    },
    data() {
        return {

        }
    },
    computed: {
        eventDate() {
            return moment(this.event.event.date).format('YYYY. MMMM DD.')
        },
        preorderStartDate() {
            return moment(this.event.event.preorderStart).format('YYYY. MMMM DD.')
        },
        preorderEndDate() {
            return moment(this.event.event.preorderEnd).format('YYYY. MMMM DD.')
        },
        available() {
            return this.event ? this.event.open : false
        },
        preorderState() {
            let state = 'not-available';

            if (
                moment(this.event.event.preorderStart) < moment()
            ) {
                state = 'available';
            }

            if (
                moment(this.event.event.preorderEnd) < moment()
            ) {
                state = 'closed';
            }

            if (moment(this.event.event.preorderStart) > moment()) {
                state = 'soon';
            }

            return state;
        }
    },
    mounted() {
        console.log(moment(), moment(this.event.event.preorderStart).add(2, 'days'));
    },
    methods: {
        openEvent() {
            this.$router.push(`/event/${this.event.id}`);
        }
    }
}
</script>
<template>
    <div :class="{'event': true, 'active': available, 'inactive': !available}">
        <div class="overlay"></div>

        <div class="image">
            <smart-image :img_key="event.event.image_keys[0]" :size="'medium'"></smart-image>
        </div>

        <div class="details">
            <h3>{{ event.event.name }}</h3>
            
            <div class="date">
                <font-awesome-icon icon="fa-solid fa-calendar-days" />
                <span class="date">
                    {{ eventDate }}
                </span>
            </div>
            <div class="date">
                {{ $t('event-end-date') }} {{ preorderEndDate }}
            </div>
            
            <p class="description">
                {{ event.event.description }}
            </p>

            <div class="pre-order">
                <button v-if="preorderState == 'available'" @click="openEvent()">
                    {{ $t('event-btn-preorder') }}
                    <span class="arrow"></span>
                </button>
                <button v-if="preorderState == 'soon'" class="inactive">
                    {{ $t('event-btn-preorder-starting_date') }} {{ preorderStartDate }}
                </button>
                <button v-if="preorderState == 'closed'" class="inactive">
                    <span>
                        {{ $t('event-btn-preorder-closed') }}
                    </span>
                </button>
                <button v-if="preorderState == 'not-available'" class="inactive">
                    <span>
                        {{ $t('event-btn-preorder-not-available') }}
                    </span>
                </button>
            </div>
        </div>
    </div>
</template>